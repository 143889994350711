.err__content {
  text-align: center;
}
.err__content img {
  width: 25%;
  margin: 0 auto;
  display: block;
}
.err__content h2 {
  margin: 10px 0;
  color: #222222;
}
.err__content p {
  line-height: 1.8;
  font-size: 16px;
  margin: 10px 0;
}
.err__content a {
  color: #ffa41d;
  font-weight: 500;
  margin-top: 20px;
  display: block;
}
